export default [
  {
    name: "A Potentially Very Long Name 1",
    position: "Papa Goose is the Alpha of V1 Green",
    portrait: "/GooseIcon.png",
    bio: "Papa Goose is the supreme ruler and founder of the V1 geese tribe. He is known to be grazing the grasses everyday from 6am to 12am in a mission to make the grasses a goosey experience for all. Papa goose will graduate from University of Waterloo in 2022 with a 4.0 GPA",
    contacts: [
      {
        icon: "/GithubIcon.png",
        url: 'http://www.github.com',
      },
      {
        icon: "/LinkedInIcon.png",
        url: 'http://www.linkedin.com',
      },
    ],
  },
  {
    name: "A Potentially Very Long Name 2",
    position: "Papa Goose is the Alpha of V1 Green",
    portrait: "/GooseIcon.png",
    contacts: [
      {
        icon: "/GithubIcon.png",
        url: 'http://www.github.com',
      },
      {
        icon: "/LinkedInIcon.png",
        url: 'http://www.linkedin.com',
      },
    ],
  },
  {
    name: "A Potentially Very Long Name 3",
    position: "Papa Goose is the Alpha of V1 Green",
    portrait: "/GooseIcon.png",
    contacts: [
      {
        icon: "/GithubIcon.png",
        url: 'http://www.github.com',
      },
      {
        icon: "/LinkedInIcon.png",
        url: 'http://www.linkedin.com',
      },
    ],
  },
  {
    name: "A Potentially Very Long Name 4",
    position: "Papa Goose is the Alpha of V1 Green",
    portrait: "/GooseIcon.png",
    contacts: [
      {
        icon: "/GithubIcon.png",
        url: 'http://www.github.com',
      },
      {
        icon: "/LinkedInIcon.png",
        url: 'http://www.linkedin.com',
      },
    ],
  },
  {
    name: "A Potentially Very Long Name 5",
    position: "Papa Goose is the Alpha of V1 Green",
    portrait: "/GooseIcon.png",
    contacts: [
      {
        icon: "/GithubIcon.png",
        url: 'http://www.github.com',
      },
      {
        icon: "/LinkedInIcon.png",
        url: 'http://www.linkedin.com',
      },
    ],
  },
  {
    name: "A Potentially Very Long Name 6",
    position: "Papa Goose is the Alpha of V1 Green",
    portrait: "/GooseIcon.png",
    contacts: [
      {
        icon: "/GithubIcon.png",
        url: 'http://www.github.com',
      },
      {
        icon: "/LinkedInIcon.png",
        url: 'http://www.linkedin.com',
      },
    ],
  },
  {
    name: "A Potentially Very Long Name 7",
    position: "Papa Goose is the Alpha of V1 Green",
    portrait: "/GooseIcon.png",
    contacts: [
      {
        icon: "/GithubIcon.png",
        url: 'http://www.github.com',
      },
      {
        icon: "/LinkedInIcon.png",
        url: 'http://www.linkedin.com',
      },
    ],
  },
  {
    name: "A Potentially Very Long Name 8",
    position: "Papa Goose is the Alpha of V1 Green",
    portrait: "/GooseIcon.png",
    contacts: [
      {
        icon: "/GithubIcon.png",
        url: 'http://www.github.com',
      },
      {
        icon: "/LinkedInIcon.png",
        url: 'http://www.linkedin.com',
      },
    ],
  },
  {
    name: "A Potentially Very Long Name 9",
    position: "Papa Goose is the Alpha of V1 Green",
    portrait: "/GooseIcon.png",
    contacts: [
      {
        icon: "/GithubIcon.png",
        url: 'http://www.github.com',
      },
      {
        icon: "/LinkedInIcon.png",
        url: 'http://www.linkedin.com',
      },
    ],
  },
  {
    name: "A Potentially Very Long Name 10",
    position: "Papa Goose is the Alpha of V1 Green",
    portrait: "/GooseIcon.png",
    contacts: [
      {
        icon: "/GithubIcon.png",
        url: 'http://www.github.com',
      },
      {
        icon: "/LinkedInIcon.png",
        url: 'http://www.linkedin.com',
      },
    ],
  },
  {
    name: "A Potentially Very Long Name 11",
    position: "Papa Goose is the Alpha of V1 Green",
    portrait: "/GooseIcon.png",
    contacts: [
      {
        icon: "/GithubIcon.png",
        url: 'http://www.github.com',
      },
      {
        icon: "/LinkedInIcon.png",
        url: 'http://www.linkedin.com',
      },
    ],
  },
  {
    name: "A Potentially Very Long Name 12",
    position: "Papa Goose is the Alpha of V1 Green",
    portrait: "/GooseIcon.png",
    contacts: [
      {
        icon: "/GithubIcon.png",
        url: 'http://www.github.com',
      },
      {
        icon: "/LinkedInIcon.png",
        url: 'http://www.linkedin.com',
      },
    ],
  },
  {
    name: "A Potentially Very Long Name 13",
    position: "Papa Goose is the Alpha of V1 Green",
    portrait: "/GooseIcon.png",
    contacts: [
      {
        icon: "/GithubIcon.png",
        url: 'http://www.github.com',
      },
      {
        icon: "/LinkedInIcon.png",
        url: 'http://www.linkedin.com',
      },
    ],
  },
  {
    name: "A Potentially Very Long Name 14",
    position: "Papa Goose is the Alpha of V1 Green",
    portrait: "/GooseIcon.png",
    contacts: [
      {
        icon: "/GithubIcon.png",
        url: 'http://www.github.com',
      },
      {
        icon: "/LinkedInIcon.png",
        url: 'http://www.linkedin.com',
      },
    ],
  },
]
